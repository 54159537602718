<template>
    <div class="page bg-white">
        <div class="flex_b_c">
            <div>
                <el-cascader class="m_r1"
                             v-model="cityId"
                             :options="cityOpt" :props="{ value: 'id', label: 'name' }"
                             size="small" @change="changeCity"></el-cascader>
                <el-select class="m_r1" v-model="hospitalId" placeholder="请选择医院" size="small" @change="changeHospital">
                    <el-option
                            v-for="item in hospitalOpt"
                            :key="item.id"
                            :label="item.hospitalName"
                            :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="departmentId" placeholder="请选择科室" size="small" @change="changeDepartment">
                    <el-option
                            v-for="item in departmentOpt"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div>
                <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">新建</el-button>
                <el-button type="danger"   size="small" icon="el-icon-delete" @click="del()"
                           :disabled="dataListSelections.length <= 0" plain>删除
                </el-button>
            </div>
        </div>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100% - 80px)"
                @selection-change="selectionChangeHandle"
                class="table">
            <el-table-column
                    type="selection"
                    width="50">
            </el-table-column>
            <el-table-column prop="requiredName" label="标配项名称" show-overflow-tooltip></el-table-column>
            <el-table-column
                    fixed="right"
                    width="200"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small"
                               icon="el-icon-view" @click="view(scope.row)">查看
                    </el-button>
                    <el-button type="text" size="small"
                               icon="el-icon-edit" @click="edit(scope.row)">修改
                    </el-button>
                    <el-button type="text" size="small"
                               icon="el-icon-delete" @click="del([scope.row.id])">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="total"
                background=""
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <RequiredForm ref="requiredForm" @refreshDataList="refreshList"></RequiredForm>
    </div>
</template>
<script>
    import RequiredForm from './requiredForm'
    export default {
        components: {
            RequiredForm
        },
        data() {
            return {
                cityId: ['1277876432646750210', '738049426362204160'],
                cityOpt: [],
                hospitalId: '',
                hospitalOpt: [],
                departmentId: '',
                departmentOpt: [],
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                loading: false,
                dataListSelections: [],
            }
        },
        activated() {
            this.getCity()
        },
        methods: {
            // 获取城市
            getCity() {
                this.loading = true
                this.$axios(this.api.order.getSysArea).then(res => {
                    if (res.status) {
                        this.cityOpt = res.data
                        this.cityOpt[0].children.map(item => {
                            if(!item.children.length) {
                                delete item.children;
                            }
                        })
                        this.getHospital()
                    }
                })
            },
            // 获取医院
            getHospital() {
                this.$axios(this.api.order.getMingenHospitalByCityId, {cityId: this.cityId[1]}).then(res => {
                    if (res.status) {
                        this.hospitalOpt = res.data
                        if(this.hospitalOpt.length) {
                            this.hospitalId = this.hospitalOpt[0].id
                            this.getDepartment()
                        }
                    }
                })
            },
            // 获取科室
            getDepartment() {
                this.$axios(this.api.order.getAllMingenDepartment, {hospitalId: this.hospitalId}).then(res => {
                    if (res.status) {
                        this.departmentOpt = res.data
                        if(this.departmentOpt.length) {
                            this.departmentId = this.departmentOpt[0].id
                        }
                        this.refreshList(1)
                    }
                })
            },
            // 选择城市
            changeCity() {
                this.hospitalId = ''
                this.departmentId = ''
                this.getHospital()
            },
            // 选择医院
            changeHospital() {
                this.departmentId = ''
                this.getDepartment()
            },
            // 选择科室
            changeDepartment() {
                this.refreshList(1)
            },
            // 获取数据列表
            refreshList(type) {
                if(type == 1) {
                    this.current = 1
                }
                this.loading = true
                this.$axios(this.api.order.requiredList, {
                    current: this.pageNo,
                    size: this.pageSize,
                    departmentId: this.departmentId
                }, 'get').then(data => {
                    if (data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // 新增
            add() {
                this.$refs.requiredForm.init('add', '')
            },
            // 修改
            edit(row) {
                let val = JSON.parse(JSON.stringify(row))
                val.cityId = this.cityId
                val.hospitalId = this.hospitalId
                val.departmentId = this.departmentId
                this.$refs.requiredForm.init('edit', val)
            },
            // 查看
            view(row) {
                let val = JSON.parse(JSON.stringify(row))
                val.cityId = this.cityId
                val.hospitalId = this.hospitalId
                val.departmentId = this.departmentId
                this.$refs.requiredForm.init('view', val)
            },
            // 删除
            del (id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.order.requiredRemove, ids, 'post').then(data => {
                        this.loading = false
                        if (data.status) {
                            this.$message.success(data.msg)
                            this.refreshList()
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 多选
            selectionChangeHandle (val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },

        }
    }
</script>
